import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default ({ selectedType, handleChangeType }) => {

    const typesVar = useStaticQuery(graphql`
        query typesVar {
            siteFields {
                types {
                    nodes {
                        name
                        slug
                        count
                    }
                }
            }
        }
    `)

    const {
        nodes: types
    } = typesVar.siteFields.types

    const _types = [{
        slug: '',
        name: 'All'
    }].concat(types)

	return (
		
        <div className="news-filter">

            <div className="container">

                <ul>

                    {_types.map((row, index) => {

                        const {
                            slug,
                            name,
                            count,
                        } = row

                        if (!count && name !== 'All') return null

                        return (

                            <li key={index} data-name={slug} className={slug === selectedType ? 'active' : '' }>

                                <button onClick={() => handleChangeType(slug)} onKeyDown={() => handleChangeType(slug)}>

                                    <span dangerouslySetInnerHTML={{ __html: name  }} />

                                </button>

                            </li>
                            
                        )

                    })}
                
                </ul>

            </div>
            
        </div>

	)
}
