import React from "react"
import WhatsOnTypes from "components/whatsOnTypes"
import NewsItem from 'components/newsItem'

export default ({ events, selectedType, handleChangeType, contentVisible, hideFilter }) => {
    const selectedTypeVar = selectedType ? selectedType : 'results'

    return (
        <>
            {hideFilter ? '' : <WhatsOnTypes selectedType={selectedType} handleChangeType={handleChangeType} />}
            <div className={`newsGrid ${contentVisible ? '' : 'loading'}`}>
                <div className="container">
                    {events.length ?
                        <ul>
                            {events.map((row, index) => {
                                return (
                                    <li key={index}>
                                        <NewsItem
                                            item={row}
                                            isWhatsOn
                                        />
                                    </li>
                                )
                            })}
                        </ul>
                        : 'Sorry, there are no matching ' + selectedTypeVar + '.'}
                </div>
            </div>
        </>
    )
}
