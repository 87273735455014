import React from "react"
import NewsItem from 'components/newsItem'

export default ({ events, contentVisible }) => {
    return (
        <>
            <div className={`newsGrid whatsOnFeatured ${contentVisible ? '' : 'loading'}`}>
                <div className="container">
                    {events.length > 0 ?
                        <ul>
                            {events.map((row, index) => {
                                return (
                                    <li key={index}>
                                        <NewsItem 
                                            item={row} 
                                            isWhatsOn
                                        />
                                    </li>
                                )
                            })}
                        </ul>
                        : 'Sorry, there are no matching results.'}
                </div>
            </div>
        </>
    )
}
