import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLazyQuery } from '@apollo/client'
import { GET_WHATSON_BY_TYPE } from '../graphql/queries'
import Moment from 'moment'

import Layout from "../components/layout"
import SEO from "../components/seo"
import TextBlock from "../components/textBlock"
import WhatsOnGrid from "../components/whatsOnGrid"
import WhatsOnGridFeatured from "../components/whatsOnGridFeatured"

export default ({ location }) => {
    const [selectedType, setSelectedType] = useState('')
    const [getWhatsOnByType, { data: eventsData, loading: eventsLoading }] = useLazyQuery(GET_WHATSON_BY_TYPE)
    const [overrideLoading, setOverrideLoading] = useState(false)

    const [selectedDate, setSelectedDate] = useState('all')

    const handleChangeDate = slug => {
        
        setSelectedDate(slug)

        filterByDate(slug)

    }

    const handleChangeType = slug => {
        
        setOverrideLoading(true)

        setTimeout(() => {
            setOverrideLoading(false)
        },200)


        setTimeout(() => {

            getWhatsOnByType({
                variables: {
                    type: slug
                },
            })
            
            filterByDate(selectedDate)

        },50)

        setSelectedType(slug)

    }

    const data = useStaticQuery(graphql`
        query events {
            siteFields {
                events(first: 1000, after: null) {
                    edges {
                        node {
                            title
                            content
                            link
                            slug
                            date
                            featuredImage {
                                node {
                                    mediaItemUrl
                                }
                            }
                            eventFields {
                                date
                                displayDate
                                excerpt
                                slideshowImage {
                                    mediaItemUrl
                                }
                            }
                            types {
                                edges {
                                    node {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    function filterByDate(selectedDate) {

        const today = new Date()
        const oneWeekInFuture = Moment(today).add(1, 'weeks')._d
        const oneMonthInFuture = Moment(today).add(3, 'months')._d

        if (selectedDate === 'today') {

            const filteredResults = _events.filter(event => Moment(event.eventFields.date).isSame(today, 'day'))

            _events = filteredResults

        } else if (selectedDate === 'thisWeek') {

            const filteredResults = _events.filter(event => Moment(event.eventFields.date).isBefore(oneWeekInFuture, 'day') && ( Moment(event.eventFields.date).isAfter(today, 'day') || Moment(event.eventFields.date).isSame(today, 'day')))

            _events = filteredResults

        } else if (selectedDate === 'thisMonth') {

            const filteredResults = _events.filter(event => Moment(event.eventFields.date).isBefore(oneMonthInFuture, 'day') && ( Moment(event.eventFields.date).isAfter(today, 'day') || Moment(event.eventFields.date).isSame(today, 'day')))

            _events = filteredResults

        }

        return _events

    }

    const {
        events: {
            edges: events
        }
    } = data.siteFields

    const eventsNodesArray = eventsData ? eventsData.events.edges : events

    let _events = eventsNodesArray.map(event => event.node)

    const isLoading = overrideLoading || eventsLoading

    if (selectedType === '') {
        _events = events.map(event => event.node)
    }

    let firstTwoEvents = ''
    let featuredEvents = ''
    let remainingEvents = ''

    if (selectedType === '' && selectedDate === 'all') {

        firstTwoEvents = _events.slice(0,3)
        featuredEvents = _events.slice(3,4)
        remainingEvents = _events.slice(4)

    }

    const heroCopy = `
        <h1 style="text-align: center; margin: 7rem 0 1rem;">What's On at Pentridge.</h1>
        <p style="text-align: center;">Stay up to date with every event at Pentridge.</p>
        <p style="text-align: center;">Want to stay in the loop? Subscribe to our email list today, to be the first to know about upcoming events.</p>
    `

    return (
        <Layout location={ location }>

            <SEO title="What's On" description="Pentridge - What's On" />

            <section class="TextBlock scrolled-into-view" style={{ backgroundColor: '#FFB600' }}>
                <TextBlock copy={heroCopy} featureColour="blue" backgroundColour="transparent" />
            </section>

            { firstTwoEvents ?

                <>

                    <WhatsOnGrid events={ firstTwoEvents } selectedType={ selectedType } handleChangeType={ handleChangeType } contentVisible={ !isLoading } />

                    { featuredEvents ?

                        <WhatsOnGridFeatured events={ featuredEvents } contentVisible={ !isLoading } />

                    : '' }

                    { remainingEvents ?

                        <WhatsOnGrid events={ remainingEvents } contentVisible={ !isLoading } hideFilter="true" />

                    : '' }

                </>

            :
            
                <WhatsOnGrid events={ filterByDate(selectedDate) } selectedType={ selectedType } handleChangeType={ handleChangeType } contentVisible={ !isLoading } />

            }

        </Layout>
    )
}
